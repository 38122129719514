
import { defineComponent, ref, toRefs, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "user-detail",
  components: {},
  emits: ["deleteUser", "editUser"],
  props: ["id"],
  setup(props, { emit }) {
    let userInfo = ref([{}]);
    let idUser = toRefs(props).id;
    let userName = "";
    let idUserToDelete = "";

    watch(
      idUser,
      (current) => {
        if (current) {
          getUserById(current);
        }
      },
      { deep: true }
    );

    const getUserById = (id) => {
      ApiService.get(`api/users/${id}`)
        .then(({ data }) => {
          let response = data.data.info;
          if (data.success) {
            userInfo.value.push(response);
            userInfo.value.shift();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };
    const deleteUser = (user) => {
      emit("deleteUser", user);
      DrawerComponent?.getInstance("kt_drawer_user")?.hide();
    };

    const editUser = (user) => {
      user.agency = user.agencies;
      emit("editUser", user);
      DrawerComponent?.getInstance("kt_drawer_user")?.hide();
    };

    return {
      userInfo,
      userName,
      idUserToDelete,
      deleteUser,
      editUser,
    };
  },
});
