
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  emits: ["delData"],
  name: "ConfirmUserDelete",
  components: {},
  props: ["id", "nombre"],
  setup(props, { emit }) {
    const newTargetModalDel = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const username = ref<string>("");

    const retData = (id) => {
      emit("delData", id);
    };
    const submit = () => {
      loading.value = true;
      let idToDel = props.id;
      ApiService.delete("api/users/" + idToDel.toString())
        .then(({ data }) => {
          if (data.success) {
            hideModal(newTargetModalDel.value);
            Swal.fire({
              text: "Usuario eliminado con éxito!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              loading.value = false;
              retData(props.id);
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const closeModal = () => {
      hideModal(newTargetModalDel.value);
    };

    return {
      submit,
      loading,
      newTargetModalDel,
      username,
      closeModal,
    };
  },
  methods: {},
  mounted() {
    //let vm = this;
    //this.username = vm.user.nombre
  },
});
