
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import SolanaServices from "@/core/services/SolanaServices";
import { Field, ErrorMessage } from "vee-validate";

interface NewAddressData {
  id?: number;
  name: string;
  last_name: string;
  email: string;
  phone: string;
  city: string;
  id_role: number;
  agency: Array<string>;
}

export default defineComponent({
  emits: ["sendData", "sendEditData"],
  name: "NewUserModal",
  props: {
    userToEdit: {
      type: Object as () => NewAddressData,
      required: false,
      default: () => {
        return {};
      },
    },
    type: {
      required: true,
      type: String,
      default: "create",
    },
    idAgency: {
      type: Number,
      default: 0,
    },
    nameAgency: {
      type: String,
      default: "",
    },
  },
  components: {
    Field,
  },
  setup(props, { emit }) {
    const errorNombre = ref<boolean>(false);
    const errorApellidos = ref<boolean>(false);
    const errorEmail = ref<boolean>(false);
    const errorTelefono = ref<boolean>(false);
    const errorRol = ref<boolean>(false);
    const errorAgencia = ref<boolean>(false);
    const errorCiudad = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const citiesCat = ref<any>([]);

    let showAgencias = ref<boolean>(false);
    let showCiudad = ref<boolean>(false);
    let user = toRefs(props).userToEdit;
    let targetData = ref<NewAddressData>({
      name: "",
      last_name: "",
      email: "",
      phone: "",
      id_role: 0,
      agency: [],
      city: "",
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let selectedAgencies: any = ref<any[]>([]);

    watch(user, (current) => {
      if (current) {
        targetData.value = current;
        getRolFromUser(current.id_role);
        if (targetData.value.agency.length !== 0) {
          selectedAgencies.value = [];
          for (const key in targetData.value.agency) {
            addAgency({ id: key, name: targetData.value.agency[key] });
          }
        }
      }
    });

    const retData = (user) => {
      emit("sendData", user);
    };

    const retEditData = (user) => {
      emit("sendEditData", user);
    };

    const getDate = () => {
      const d = new Date();
      let month = "" + d.getMonth() + 1;
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      let day = d.getDate().toString();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      let hour = d.getHours().toString();
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      let minutes = d.getMinutes().toString();
      if (minutes.toString().length == 1) {
        minutes = "0" + minutes;
      }
      return d.getFullYear() + "/" + month + "/" + day + " " + hour + ":" + minutes;
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Escribe el nombre",
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Escribe los apellidos",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Escribe el correo",
          trigger: "blur",
        },
        {
          email: true,
          message: "Formato de correo incorrecto",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          message: "Escribe el teléfono",
          trigger: "blur",
        },
      ],
      city: [
        {
          required: true,
          message: "Selecciona una ciudad",
          trigger: "blur",
        },
      ],
      id_rol: [
        {
          required: true,
          message: "Selecciona rol",
          trigger: "change",
        },
      ],
      agency: [
        {
          required: true,
          message: "Selecciona agencias",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          targetData.value.agency = selectedAgencies.value.map(
            (agency) => Object.keys(agency)[0]
          );
          if (props.idAgency > 0) {
            if (targetData.value.agency.indexOf(props.idAgency.toString()) === -1) {
              targetData.value.agency.push(props.idAgency.toString());
            }
          }

          const dataUser = JSON.parse(JSON.stringify(targetData.value));
          // dataUser.password = "Tu1nrApp$";
          dataUser.id_role = parseInt(dataUser.id_role);

          if (!dataUser.id) {ApiService.post("api/auth/signup", dataUser).then(({ data }) => {
              if (data.success) {
                let responseUser = data.data;
                let userData = JSON.parse(JSON.stringify(targetData.value));
                userData.id = responseUser.id_user;
                userData.createdAt = getDate();
                
                Swal.fire({
                  text: "¡Usuario registrado con éxito!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  closeModal();
                  loading.value = false;
                  retData(userData);
                });
              }
            }).catch(({ response }) => {
              if (response !== undefined) {
                let errors = "";
                
                for (let key in response.data.errors) {
                  let error = response.data.errors[key];

                  if (key == "name") {
                    errorNombre.value = true;
                  } else if (key == "last_name") {
                    errorApellidos.value = true;
                  } else if (key == "email") {
                    errorEmail.value = true;
                  } else if (key == "phone") {
                    errorTelefono.value = true;
                  } else if (key == "id_role") {
                    errorRol.value = true;
                  } else if (key == "agency") {
                    errorAgencia.value = true;
                  } else if (key == "city") {
                    errorCiudad.value = true;
                  }

                  for (let keyError in error) {
                    let errorMsg = error[keyError];
                    errors += ` ${errorMsg} `;
                  }
                }
                
                // console.log(errors);

                Swal.fire({
                  icon: "error",
                  text: errors,
                  title: `Error al registrar usuario`,
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });

                loading.value = false;
              }
            });
          } else {
            ApiService.put(`api/users/${dataUser.id}`, dataUser)
              .then(({ data }) => {
                if (data.success) {
                  Swal.fire({
                    text: "¡Usuario actualizado con éxito!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Aceptar",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                    closeModal();
                    loading.value = false;
                    retEditData(dataUser);
                  });
                }
              })
              .catch(({ response }) => {
                if (response !== undefined) {
                  let errors = "";
                  for (let key in response.data.errors) {
                    let error = response.data.errors[key];
                    if (key == "name") {
                      errorNombre.value = true;
                    }
                    if (key == "last_name") {
                      errorApellidos.value = true;
                    }
                    if (key == "email") {
                      errorEmail.value = true;
                    }
                    if (key == "phone") {
                      errorTelefono.value = true;
                    }
                    if (key == "id_role") {
                      errorRol.value = true;
                    }
                    if (key == "agency") {
                      errorAgencia.value = true;
                    }
                    if (key == "city") {
                      errorCiudad.value = true;
                    }
                    console.log(key);
                    for (let keyError in error) {
                      let errorMsg = error[keyError];
                      errors += `${errorMsg}`;
                    }
                  }
                  loading.value = false;
                  /* Swal.fire({
                    title: `!${response.data.message}!`,
                    html: `${errors}</br>`,
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: "Aceptar",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then((errors) => {
                    return errors;
                  }); */
                }
              });
          }
        } else {
          return false;
        }
      });
    };

    const removeError = (field) => {
      if (field == "nombre") {
        errorNombre.value = false;
      }
      if (field == "apellidos") {
        errorApellidos.value = false;
      }
      if (field == "correo") {
        errorEmail.value = false;
      }
      if (field == "telefono") {
        errorTelefono.value = false;
      }
    };

    let roles: any = [];

    ApiService.get("api/roles")
      .then(({ data }) => {
        if (data.success) {
          let response = data.data.role;
          //console.log("roles", response);
          for (let key in response) {
            let value = response[key];
            if (props.idAgency > 0) {
              if (value.id_role_condition == 2) {
                let dataRol = {
                  id: value.id,
                  name_rol: value.name_rol,
                  id_role_condition: value.id_role_condition,
                };
                roles.push(dataRol);
              }
            } else {
              let dataRol = {
                id: value.id,
                name_rol: value.name_rol,
                id_role_condition: value.id_role_condition,
              };
              roles.push(dataRol);
            }
          }
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });

    let agencies: any = [];

    ApiService.get("api/agencies?showing=100")
      .then(({ data }) => {
        if (data.success) {
          let response = data.data.agency.data;
          for (let key in response) {
            if (props.idAgency > 0) {
              if (response[key].id == props.idAgency) {
                let agency = {
                  id: response[key].id,
                  name: response[key].name,
                };
                agencies.push(agency);
              }
            } else {
              let agency = {
                id: response[key].id,
                name: response[key].name,
              };
              agencies.push(agency);
            }
          }
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });

    const addAgency = (agency) => {
      errorAgencia.value = false;
      const { id, name } = agency;
      let agencyToArray = {};
      agencyToArray[id] = name;
      let selectedRole = roles.find((role) => role.id === targetData.value.id_role);
      if (selectedRole.id_role_condition == 3) {
        let isAgencyInArray = selectedAgencies.value
          .map((val) => Object.values(val))
          .flat()
          .includes(name);
        if (!isAgencyInArray) {
          selectedAgencies.value.push(agencyToArray);
        }
      } else if (selectedRole.id_role_condition == 2) {
        selectedAgencies.value = [agencyToArray];
      }
    };

    const removeAgency = (index) => {
      if (index > -1) {
        selectedAgencies.value.splice(index, 1);
        targetData.value.agency = [];
      }
    };

    const closeModal = () => {
      if (props.type == "create") {
        selectedAgencies.value = [];
        targetData.value = {
          id: undefined,
          name: "",
          last_name: "",
          email: "",
          phone: "",
          id_role: 0,
          agency: [],
          city: "",
        };
        showAgencias.value = false;
        showCiudad.value = false;
      }
      hideModal(newTargetModalRef.value);
    };

    const getRolFromUser = (event) => {
      let selectedRole = roles.find((role) => role.id === event);
      if (selectedRole) {
        switch (selectedRole.id_role_condition) {
          case 1: //Tipo corporativo
            errorRol.value = false;
            showAgencias.value = false;
            showCiudad.value = false;
            break;
          case 2: //Tipo agencia
            errorRol.value = false;
            showAgencias.value = true;
            showCiudad.value = false;
            selectedAgencies.value = [];
            break;
          case 3: //Tipo agencia múltiples agencias
            errorRol.value = false;
            showAgencias.value = true;
            showCiudad.value = false;
            selectedAgencies.value = [];
            break;
          case 4: //Tipo ciudad
            errorRol.value = false;
            showAgencias.value = false;
            showCiudad.value = true;
            break;
          default:
            errorRol.value = true;
            showAgencias.value = false;
            showCiudad.value = false;
            break;
        }
      }
    };

    const cambiaCiudad = () => {
      errorCiudad.value = false;
    };

    const getCiudades = async () => {
      const { data } = await SolanaServices.getCiudades();
      citiesCat.value = data.data;
    };

    onMounted(async () => {
      await getCiudades();
    });

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      roles,
      showAgencias,
      showCiudad,
      agencies,
      retData,
      closeModal,
      retEditData,
      getRolFromUser,
      addAgency,
      selectedAgencies,
      removeAgency,
      removeError,
      errorNombre,
      errorApellidos,
      errorEmail,
      errorTelefono,
      errorRol,
      errorAgencia,
      errorCiudad,
      cambiaCiudad,
      citiesCat,
      getCiudades,
    };
  },
  methods: {},
});
