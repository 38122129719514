
import { defineComponent, ref, onMounted, toRefs, watch } from "vue";
import KTDatatable from "@/components/users/Users.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ModalNewUser from "@/components/users/NewUserModal.vue";
import ModalDeleteUser from "@/components/users/ConfirmUserDelete.vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import DrawerUserDetail from "@/components/users/DrawerUserDetail.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {useRoute} from 'vue-router'
import {computed} from 'vue'
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "Users",
  components: {
    KTDatatable,
    ModalNewUser,
    ModalDeleteUser,
    DrawerUserDetail,
    Field,
  },
  props: {
    sub: {
      type: Boolean,
      default: false,
    },
    idAgency: {
      type: Number,
      default: 0,
    },
    nameAgency: {
      type: String,
      default: "",
    },
    showAgency: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const dataUsers = ref<any>([]);
    const idToDelete = ref<number>(0);
    const userToDelete = ref<string>("");
    const loading = ref<boolean>(true);
    const userToEdit = ref({});
    let idUser = ref<number>(0);
    let createNewUser = ref<string>("");
    moment.locale("es");
    //Catálogos
    const roles = ref<string[]>([]);
    const agencies = ref<string[]>([]);
    //Paginación
    const links = ref<any>([]);
    const showing = ref<number>(10);
    const total = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    //Filtros
    let searchWord = ref<string>("");
    const searchIdRole = ref<any>("Rol");
    const searchIdAgency = ref<any>("Agencia");
    let params = {};
    let areUsers = ref<boolean>(true);
    const noResults = ref<boolean>(false);
    const userRole = ref<any>();
    const showAgency = toRefs(props).showAgency;
    const route=useRoute();
    const path = computed(() =>route.path);

    //Obtener catálogos de roles para filtro
    const getRoles = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/roles";
        const { data } = await axios.get(url);
        roles.value = data.data.role;
      } catch (error) {
        console.log(error);
      }
    };

    const dontOpen = () => {
      console.log(" ");
    };

    //Obtener catálogo de agencias para filtro
    const getAgencies = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/agencies?showing=100";
        const { data } = await axios.get(url);
        agencies.value = data.data.agency.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getUserIdDel = (id) => {
      for (let key in dataUsers.value) {
        let value = dataUsers.value[key];
        if (value["id"] == id.toString()) {
          dataUsers.value.splice(Number(key), 1);
        }
      }
    };

    const getUserIdEdit = async () => {
      dataUsers.value.length = 0;
      idUser.value = 0;
      await getRoles();
      await getAgencies();
      await getUsers();
    };

    const getUserData = async () => {
      dataUsers.value.length = 0;
      idUser.value = 0;
      await getRoles();
      await getAgencies();
      await getUsers();
    };

    const openDrawer = (user) => {
      idUser.value = user.id;
      DrawerComponent?.getInstance("kt_drawer_user")?.toggle();
    };

    const headerConfig = ref<any>([
      {
        name: "Nombre",
        key: "nombre",
        sortable: true,
      },
      {
        name: "Correo",
        key: "correo",
        sortable: true,
      },
    ]);

    const getUsers = async (page = "") => {
      //Filtrar usuarios por id de Agencia
      if (props.idAgency > 0) {
        searchIdAgency.value = props.idAgency;
        searchByAgency();
      } else {
        loading.value = true;
        try {
          let url = "";
          if ((page == "") && ( !params["idAgency"] && !params["idRole"] && !params["nameUser"]) ) {
            url =
              process.env.VUE_APP_API_URL +
              "/api/users?showing=" +
              showing.value;

          } else if (  (params["idAgency"] || params["idRole"] || params["nameUser"])) {
             
             return await searchAll(page)
            
          } else {
            url = page; // + "?showing=" + showing.value;
          }

          const { data } = await axios.get(url);
          if (data.success) {
            total.value = data.data.total;
            links.value = data.data.links;
            let users = data.data.data.users;
            from.value = data.data.from;
            to.value = data.data.to;
            if (users) {
              noResults.value = false;
              //areUsers.value = true;
              users.forEach(function (value) {
                dataUsers.value.push({
                  id: value.id,
                  name: value.name,
                  last_name: value.last_name,
                  email: value.email,
                  phone: value.phone,
                  city: value.city,
                  role: value.name_role,
                  id_role: value.id_role,
                  name_agency: value.name_agency,
                  agency: value.agencies,
                  createdAt: moment(value.created_at).format("L"),
                });
              });
            } else {
              noResults.value = true;
              //areUsers.value = false;
            }

            loading.value = false;
          } else {
            loading.value = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    //Filtros
    const searchItems = _.debounce(async function () {
      loading.value = true;
      if (searchWord.value != "") {
        params["nameUser"] = searchWord.value;
        searchAll();
      } else {
        delete params["nameUser"];
        if (searchIdRole.value > 0 || searchIdAgency.value > 0) {
          params["idRole"] = searchIdRole.value;
          params["idAgency"] = searchIdAgency.value;
          searchAll();
        } else {
          dataUsers.value.length = 0;
          links.value.splice(0);
          await getUsers();
        }
      }
      loading.value = false;
    }, 500);

    const searchByRole = () => {
      loading.value = true;
      if (searchIdRole.value != "Rol") {
        params["idRole"] = searchIdRole.value;
        searchAll();
      } else {
        delete params["idRole"];
        if (searchIdRole.value > 0 || searchIdAgency.value > 0) {
          params["idAgency"] = searchIdAgency.value;
          params["nameUser"] = searchWord.value;
          searchAll();
        } else {
          dataUsers.value.length = 0;
          links.value.splice(0);
          getUsers();
        }
      }
      loading.value = false;
    };

    const searchByAgency = () => {
      loading.value = true;
      if (searchIdAgency.value != "Agencia") {
        params["idAgency"] = searchIdAgency.value;
        searchAll();
      } else {
        delete params["idAgency"];
        if (searchIdRole.value > 0 || searchIdAgency.value > 0) {
          params["idRole"] = searchIdRole.value;
          params["nameUser"] = searchWord.value;
          searchAll();
        } else {
          dataUsers.value.length = 0;
          links.value.splice(0);
          getUsers();
        }
      }
      loading.value = false;
    };

    const searchAll = async (linkUrl = "") => {
      try {
        dataUsers.value.length = 0;
        links.value.splice(0);
        let url = process.env.VUE_APP_API_URL + "/api/users?showing=" +
            showing.value;

        if (linkUrl != ""){
          url = linkUrl
        }

        const { data } = await axios.get(url, {
          params: params,
        });
        total.value = data.data.total;
        links.value = data.data.links;
        let users = data.data.data.users;
        from.value = data.data.from;
        to.value = data.data.to;
        if (users) {
          noResults.value = false;
          //areUsers.value = true;
          users.forEach(function (value) {
            dataUsers.value.push({
              id: value.id,
              name: value.name,
              last_name: value.last_name,
              email: value.email,
              phone: value.phone,
              city: value.city,
              role: value.name_role,
              id_role: value.id_role,
              name_agency: value.name_agency,
              agency: value.agencies,
              createdAt: moment(value.created_at).format("L"),
            });
          });
        } else {
          noResults.value = true;
          //areUsers.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    //Paginación
    const showingSet = async () => {
      dataUsers.value.splice(0);
      links.value.splice(0);
      await getUsers();
    };

    const getPagination = async (link) => {
      dataUsers.value.splice(0);
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      await getUsers(urlProd);
    };

    onMounted(async () => {
      let userRolObj:any = localStorage.getItem('userRole');
      userRole.value = JSON.parse(userRolObj);
      if(userRole.value.tipo.name != undefined && userRole.value.tipo.name == 'Corporativo'){
        headerConfig.value.push({
          name: "Rol",
          key: "rol",
          sortable: true,
        });
        headerConfig.value.push({
          name: "Agencia",
          key: "agencia",
          sortable: true,
        });
        if(path.value == '/users'){
          headerConfig.value.push({
            name: "Creado el",
            key: "createdAt",
            sortable: true,
          });
        }else{
          headerConfig.value.push({
            name: "Asignado el",
            key: "createdAt",
            sortable: true,
          });
        }
        headerConfig.value.push({
          name: "Acciones",
          key: "actions",
        });
      }else{
        headerConfig.value.push({
          name: "Asignado el",
          key: "createdAt",
          sortable: true,
        });
      }

      watch(showAgency, async (current) => {
        if(!current){
          headerConfig.value.splice(3, 1);
        }
      });

      setCurrentPageBreadcrumbs("Usuarios", []);
      await getRoles();
      await getAgencies();
      await getUsers();
    });

    return {
      dataUsers,
      headerConfig,
      getUserData,
      userToDelete,
      idToDelete,
      loading,
      getUserIdDel,
      idUser,
      userToEdit,
      getUserIdEdit,
      openDrawer,
      createNewUser,
      getRoles,
      roles,
      agencies,
      getAgencies,
      links,
      showing,
      total,
      from,
      to,
      showingSet,
      getPagination,
      searchWord,
      searchItems,
      searchIdRole,
      searchIdAgency,
      searchAll,
      searchByRole,
      searchByAgency,
      areUsers,
      dontOpen,
      noResults,
      userRole,
      route,
      path
    };
  },
  methods: {
    deleteUser(user) {
      this.userToDelete = user.name;
      this.idToDelete = user.id;
    },
    editUser(user) {
      this.userToEdit = { ...user };
      this.createNewUser = "";
    },
    cleanUserToEdit() {
      this.userToEdit = {
        agency: [],
        city: null,
        createdAt: "",
        email: "",
        name_agency: "",
        id: "",
        id_role: 0,
        last_name: "",
        name: "",
        phone: "",
        role: "",
      };
      this.createNewUser = "create";
    },
  },
});
